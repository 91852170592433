import {
    type openSession,
    type isPushSupported,
    type isPushPermissionGranted,
    type isPushBlocked,
    type requestPushPermission,
    type unregisterPush,
    type logCustomEvent,
  } from '@braze/web-sdk'
  
  let braze: {
    openSession: typeof openSession
    isPushSupported: typeof isPushSupported
    isPushPermissionGranted: typeof isPushPermissionGranted
    isPushBlocked: typeof isPushBlocked
    requestPushPermission: typeof requestPushPermission
    unregisterPush: typeof unregisterPush
    logCustomEvent: typeof logCustomEvent
  }

  export default async function () {
    if (!braze && import.meta.client) {
      const $config = useRuntimeConfig()
      braze = await createBraze($config.public.braze.apiKey, $config.public.braze.sdkUrl)
    }

    const canRequestPushMessagesPermission = () => {
      return braze.isPushSupported() && !braze.isPushPermissionGranted() && !braze.isPushBlocked()
    }
  
    const isWebPushSupported = () => {
      return braze.isPushSupported()
    }
  
    const isWebPushBlocked = () => {
      return braze.isPushBlocked()
    }
  
    const isWebPushAllowed = () => {
      return braze.isPushPermissionGranted()
    }
  
    const requestPushMessagesPermission = (success: () => void, denied: (arg0: boolean) => void) => {
      braze.requestPushPermission(
        () => success(),
        (isTemporary) => denied(isTemporary),
      )
    }
  
    const removePushMessagePermission = () => {
      braze.unregisterPush()
    }

    return {
      openSession: braze?.openSession,
      logCustomEvent: braze?.logCustomEvent,
      canRequestPushMessagesPermission,
      isWebPushSupported,
      isWebPushBlocked,
      isWebPushAllowed,
      requestPushMessagesPermission,
      removePushMessagePermission,
    }
  }