<template>
  <TooltipDesktop
    v-if="isLargeScreen"
    :reference-element="referenceElement"
    :show="show"
    :content="content"
    @close="closeHandler"
  />
  <TooltipMobile v-else :show="show" :content="content" @close="closeHandler" />
</template>

<script setup lang="ts">
defineProps<{
  show?: boolean
  referenceElement?: InstanceType<any>
  content: string
}>()

const emit = defineEmits<{
  close: [value: void]
}>()

const isLargeScreen = useIsLargeScreen()

const closeHandler = () => emit('close')
</script>
