<template>
  <div v-if="show" ref="floatingEl" class="bg-primary z-50 py-2 px-4 rounded body-1 text-white" :style="floatingStyles">
    <TrustedHtml :html="content" />
    <div v-if="showArrow" ref="arrowEl" class="bg-primary size-2 absolute rotate-45" :style="arrowStyle" />
  </div>
</template>

<script setup lang="ts">
import { arrow, autoPlacement, offset, useFloating } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps<{
  show?: boolean
  referenceElement: InstanceType<any>
  content: string
  showArrow?: boolean
  ignoreClickOutside?: boolean
}>()

const emit = defineEmits<{
  close: [value: void]
}>()

const arrowEl = ref<HTMLDivElement>()
const floatingEl = ref<HTMLDivElement>()
const targetEl = ref<InstanceType<any>>(props.referenceElement)

const { floatingStyles, middlewareData } = useFloating(targetEl, floatingEl, {
  middleware: [
    offset(4),
    autoPlacement({
      allowedPlacements: ['top', 'bottom']
    }),
    arrow({
      element: arrowEl
    })
  ]
})

onClickOutside(floatingEl, (event) => {
  if (!props.ignoreClickOutside && !event.composedPath().includes(targetEl.value?.$el)) {
    emit('close')
  }
})

const arrowStyle = computed(() => {
  if (!floatingStyles.value) return

  const arrow = middlewareData.value?.arrow ?? { x: null, y: null }
  const offset = middlewareData.value?.offset ?? { x: 0, y: 0 }
  return {
    top:
      arrow.y != null
        ? `${arrow.y - offset.y}px`
        : `${(floatingEl.value?.clientHeight ?? 0) - (arrowEl.value?.clientHeight ?? 0) / 2}px`,
    left: arrow.x != null ? `${arrow.x - offset.x}px` : '',
    willChange: floatingStyles.value.willChange
  }
})
</script>
