import { createApp, type DirectiveBinding } from 'vue'
import TooltipComponent from '~/components/tooltip/index.vue'

type HTMLElementWithTooltip = HTMLElement & { __vueTooltipApp__: any }

export default defineNuxtPlugin((nuxtApp) => {
  const root = document.createElement('div')
  root.id = 'v-tooltip-mount'
  document.body.appendChild(root)

  const mounted = (el: HTMLElementWithTooltip, binding?: DirectiveBinding) => {
    if (!binding?.value?.content) return

    const closePopup = () => {
      props.show = false
    }

    const props: {
      show?: boolean
      content: string
      referenceElement: HTMLElementWithTooltip
      ignoreClickOutside?: boolean
      showArrow?: boolean
      onClose: Function
    } = reactive({
      content: binding.value.content,
      referenceElement: el,
      onClose: closePopup
    })

    const app = createApp({
      render() {
        return h(TooltipComponent, props, {})
      }
    })

    const mountPoint = document.createElement('div')
    root.appendChild(mountPoint)
    app.mount(mountPoint)

    el.classList.add('cursor-pointer')

    switch (binding.value.triggerOn) {
      case 'hover':
        props.ignoreClickOutside = true
        el.addEventListener('mouseover', () => {
          props.show = true
        })
        el.addEventListener('mouseleave', () => {
          props.show = false
        })
        break

      default:
        props.ignoreClickOutside = false
        el.addEventListener('click', () => {
          props.show = !props.show
        })
        break
    }

    el.__vueTooltipApp__ = app
  }

  const unmounted = (el: HTMLElementWithTooltip) => {
    el.__vueTooltipApp__?.unmount()
    delete el.__vueTooltipApp__
  }

  const updated = (el: HTMLElementWithTooltip, binding: DirectiveBinding) => {
    unmounted(el)
    mounted(el, binding)
  }

  nuxtApp.vueApp.directive('tooltip', {
    mounted,
    unmounted,
    updated
  })
})
