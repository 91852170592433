<template>
  <Drawer :show="show" @close="emit('close')">
    <template #content>
      <TrustedHtml class="body-1" :html="content" />
    </template>
  </Drawer>
</template>

<script setup lang="ts">
defineProps<{
  show?: boolean
  content: string
}>()

const emit = defineEmits<{
  close: [value: void]
}>()
</script>
