export default (error: Error, context: Record<string, any> | undefined = undefined) => {
  logger.error({ stack: error.stack, type: error.name, context }, error.message)
  try {
    if (process.client) {
      window.DD_RUM?.addError(new Error(error.message), { cause: error?.message ?? 'Unknown' })
    }
  } catch {
    // do nothing
  }
}
