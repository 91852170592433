import { useMediaQuery } from '@vueuse/core'

export default (): Ref<boolean> => {
  if (process.server) {
    const { isDesktop } = useDevice()
    return ref(isDesktop)
  }

  return useMediaQuery('(min-width: 1024px)')
}
