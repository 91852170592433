<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const $config = useRuntimeConfig()
const { locale } = useI18n()
const i18nHead = useLocaleHead({
  addSeoAttributes: true
})

const headMeta = computed(() => {
  const metadata = {
    'og:type': {
      key: 'og:type',
      property: 'og:type',
      content: 'website'
    },
    'fb:app_id': {
      key: 'fb:app_id',
      property: 'fb:app_id',
      content: $config.public.facebook.appId
    }
  } as Record<string, any>

  for (const item of i18nHead.value.meta || []) {
    const key = item.key || uuid()
    metadata[key as string] = item
  }

  return Object.values(metadata)
})

useInitApp()

function useInitApp() {
  onMounted(async () => {
    const { openSession } = await useBraze()
    // Open a new Braze session
    openSession()
  })
}

useHead({
  link: [
      ...i18nHead.value.link,
    {
      rel: 'dns-prefetch',
      href: $config.public.app.campgrounds.apiEndpoint
    },
    {
      rel: 'preconnect',
      href: $config.public.app.campgrounds.dynamicCdnEndpoint
    },
    {
      rel: 'preconnect',
      href: $config.public.app.campgrounds.staticCdnEndpoint
    },
    {
      rel: 'preconnect',
      href: $config.public.app.campgrounds.imagesCdnEndpoint
    },
    {
      rel: 'dns-prefetch',
      href: $config.public.app.rentals.apiEndpoint
    },
    {
      rel: 'dns-prefetch',
      href: $config.public.app.rentals.dynamicCdnEndpoint
    },
    {
      rel: 'dns-prefetch',
      href: $config.public.app.gis.apiEndpoint
    },
    {
      rel: 'preconnect',
      href: $config.public.segment.url
    }
  ],
  htmlAttrs: {
    lang: locale.value
  },
  meta: [...(headMeta.value || [])]
})

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'OnlineStore',
  name: 'RVezy',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '245 Cooper Street',
    postalCode: 'K2P 0G2',
    addressLocality: 'Ottawa',
    addressRegion: 'Ontario',
    addressCountry: 'CA'
  },
  url: $config.public.app.rentals.baseUrl,
  telephone: '1-855-697-8399',
  logo: 'https://cdn-assets-cloud.frontify.com/s3/frontify-cloud-files-us/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL0FQRDdXeUtyQTlrU05SV25DdzRvLnBuZyJ9:frontify:7f3CsFZg8w2E-IlTGY1U_Dn0t3qhy4Dj3W8V678l2LU',
  image: 'https://cdn-assets-cloud.frontify.com/s3/frontify-cloud-files-us/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL0FQRDdXeUtyQTlrU05SV25DdzRvLnBuZyJ9:frontify:7f3CsFZg8w2E-IlTGY1U_Dn0t3qhy4Dj3W8V678l2LU'
}))

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: $config.public.app.campgrounds.baseUrl,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${$config.public.app.campgrounds.baseUrl}/${locale.value}/search?SearchAddress={destination}`,
    query: 'required name=destination'
  }
}))
</script>
