import { default as indexYvalk2vKegMeta } from "/home/vsts/work/1/s/web/src/pages/index.vue?macro=true";
import { default as index9e846gndH2Meta } from "/home/vsts/work/1/s/web/src/pages/[...place]/index.vue?macro=true";
import { default as _91slug_93eNpVyCs5qDMeta } from "/home/vsts/work/1/s/web/src/pages/c/[slug].vue?macro=true";
import { default as _91slug_93QsjpGtnC28Meta } from "/home/vsts/work/1/s/web/src/pages/campground/[slug].vue?macro=true";
import { default as index9Tt8kGzNFlMeta } from "/home/vsts/work/1/s/web/src/pages/search/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "place___en",
    path: "/en/:place(.*)*",
    meta: index9e846gndH2Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/[...place]/index.vue").then(m => m.default || m)
  },
  {
    name: "place___fr",
    path: "/fr/:place(.*)*",
    meta: index9e846gndH2Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/[...place]/index.vue").then(m => m.default || m)
  },
  {
    name: "c-slug___en",
    path: "/en/c/:slug()",
    meta: _91slug_93eNpVyCs5qDMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/c/[slug].vue").then(m => m.default || m)
  },
  {
    name: "c-slug___fr",
    path: "/fr/c/:slug()",
    meta: _91slug_93eNpVyCs5qDMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/c/[slug].vue").then(m => m.default || m)
  },
  {
    name: "campground-slug___en",
    path: "/en/campground/:slug()",
    meta: _91slug_93QsjpGtnC28Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/campground/[slug].vue").then(m => m.default || m)
  },
  {
    name: "campground-slug___fr",
    path: "/fr/campground/:slug()",
    meta: _91slug_93QsjpGtnC28Meta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/campground/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexYvalk2vKegMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: index9Tt8kGzNFlMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: index9Tt8kGzNFlMeta || {},
    component: () => import("/home/vsts/work/1/s/web/src/pages/search/index.vue").then(m => m.default || m)
  }
]