export default defineNuxtRouteMiddleware((to, from) => {
  // skip middleware on server
  if (process.server) return

  let path = to.path || '/'

  if (to.matched.length) {
    const matchedPath = to.matched.at(-1)?.path || '/'
    path = matchedPath.replace(/(:\S+?)\b/gm, '?')
  }

  datadogStartView(path)
})
